/*
* Bootstrap overrides https://getbootstrap.com/docs/4.0/getting-started/theming/
* All values defined in bootstrap source
* https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss can be overwritten here
* Make sure not to add !default to values here
*/

$theme-colors: () !default;
$theme-colors: map-merge(
  (
    "primary":    #009fe3,
    // "secondary":  $secondary,
    // "success":    $success,
    // "info":       $info,
    // "warning":    $warning,
    // "danger":     $danger,
    // "light":      $light,
    // "dark":       $dark
  ),
  $theme-colors
);
