@import 'values';

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  background: $backgroundColor;
}

ul {
  margin: 0;
}

input,
input.form-control,
select.form-control {
  color: $textColor;
}

.text-danger {
  color: $appRed !important;
}

button,
input[type='button'] {
  cursor: pointer;
}

button:focus,
input:focus,
textarea:focus {
  outline: none;
}

input,
textarea {
  border: none;
}

button {
  border: none;
  background: none;
}

img {
  max-width: 100%;
  height: auto;
}

p {
  margin: 0;
}

.table-responsive {
  padding-right: 1px;
}

a {
  color: $appColor;
}

a:hover {
  color: $appColor;
  text-decoration: underline;
}

.card {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.btn {
  font-family: $fontFamilyCommon;
  font-weight: 600;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  line-height: 2.4rem;
  padding: 0 10px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  cursor: pointer;
}

body {
  font-family: $fontFamilyCommon;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.625rem;
  color: $textColor;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $fontFamilySpot;
  color: $textColor;
  font-weight: 500;
  margin: 0;
  line-height: 1.2;
}

blockquote {
  margin: 0;
}

strong {
  font-weight: 600;
}

.form-control,
.input-group-text {
  border-color: $inputBorderColor;
}

.input-group-text {
  margin-bottom: 0;
  padding: 0 0.75rem;
  line-height: 1rem;
}

.form-control:disabled,
.form-control[readonly] {
  background: $inputDisabledBackgroundColor;
}

.form-group label {
  font-size: 90%;
  font-weight: 600;
  margin-left: 0.1rem;
  //margin-bottom: 0.2rem;
}

.form-group .input-group {
  label {
    font-size: 100%;
    background: #fff;
    color: $textColor;
    font-weight: 600;
    margin-left: 0;
  }
}

.form-control.selector {
  padding: 0;

  .ng-select {
    .ng-select-container {
      border: none;
    }

    &.ng-select-disabled > .ng-select-container {
      background: $inputDisabledBackgroundColor;
    }
  }
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.alert-success {
  -webkit-box-shadow: 0 1px 5px 0 #c3e6cb;
  -moz-box-shadow: 0 1px 5px 0 #c3e6cb;
  box-shadow: 0 1px 5px 0 #c3e6cb;
}

.alert-danger {
  -webkit-box-shadow: 0 1px 5px 0 #f5c6cb;
  -moz-box-shadow: 0 1px 5px 0 #f5c6cb;
  box-shadow: 0 1px 5px 0 #f5c6cb;
}

.alert-info {
  -webkit-box-shadow: 0 1px 5px 0 #bee5eb;
  -moz-box-shadow: 0 1px 5px 0 #bee5eb;
  box-shadow: 0 1px 5px 0 #bee5eb;

  color: #414141;
  background-color: #ebebeb !important;
  border-color: #cbcbcb;

  h4 {
    font-size: 16px;
    margin-top: 10px;
    font-weight: bold;
  }
}

.alert-warning {
  -webkit-box-shadow: 0 1px 5px 0 #ffeeba;
  -moz-box-shadow: 0 1px 5px 0 #ffeeba;
  box-shadow: 0 1px 5px 0 #ffeeba;
}

.form-group .text-danger {
  font-weight: 600;
}

.form-group .text-warning {
  font-weight: 600;
}

table.table.table-hover {
  border: 1px hidden;
  border-radius: 8px;
  color: $textColor;

  tbody tr td {
    //border: 1px hidden;
  }

  th,
  td {
    padding: 0.25rem;
    vertical-align: middle;
    border-top: 1px solid $backgroundColor;
  }

  thead.thead-light {
    font-size: 85%;
    font-family: $fontFamilySpot;
    color: $textColor;

    th {
      //background: #eeedf5;
      background: none;
      border: none;
      cursor: pointer;
      color: $textColor;

      &.icon {
        width: 30px;
        max-width: 30px;
      }

      &.action {
        width: 50px;
        max-width: 50px;
      }
    }
  }

  tbody {
    tr {
      &:hover {
        color: $textColor;
        background: #fbfdff;
      }

      &.notActive {
        opacity: 0.5;
        text-decoration: line-through;
      }

      td {
        border-top: 1px solid #f5f6ff;

        &.dirty-warning {
          color: $dirtyWarningColor;
        }
      }
    }
  }
}

.dropdown-menu {
  padding: 0;
  border: 1px solid #dbe5ec;

  .dropdown-item {
    color: $textColor;
    padding: 8px 12px;
  }

  .dropdown-divider {
    padding: 0;
    margin: 2px 0;
  }
}

.bg-brand {
  background-color: #ed6923 !important;
}

.btn-brand {
  color: #ffffff;
  background-color: #ed6923;
  border-color: #ecb2a3;
}

.btn-brand:hover {
  color: #ffffff;
  background-color: #d75e1e;
  border-color: #ecb2a3;
}

.btn-brand:focus,
.btn-brand.focus {
  color: #ffffff;
  background-color: #f67b3a;
  border-color: #ecb2a3;
  box-shadow: 0 0 0 0.2rem rgba(216, 216, 216, 0.5);
}

.btn-brand.disabled,
.btn-brand:disabled {
  color: #ffffff;
  background-color: #db9f7d;
  border-color: #c98f7f;
}

.btn-brand:not(:disabled):not(.disabled):active,
.btn-brand:not(:disabled):not(.disabled).active,
.show > .btn-brand.dropdown-toggle {
  color: #ffffff;
  background-color: #fc722b;
  border-color: #f16337;
}

.btn-brand:not(:disabled):not(.disabled):active:focus,
.btn-brand:not(:disabled):not(.disabled).active:focus,
.show > .btn-brand.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(195, 195, 195, 0.5);
}

.btn-default {
  color: $textColor;
  background-color: #eef0f8;
  border-color: #dbe5ec;
}

.btn-success {
  color: white;
  background-color: $appGreen;
  border-color: $appGreen;
}

.btn-default:hover {
  color: $textColor;
  background-color: #c0c3cf;
  border-color: #b4b3c7;
}

.btn-default:focus,
.btn-default.focus {
  color: $textColor;
  background-color: #d1d4d7;
  border-color: #b0b1b4;
  box-shadow: 0 0 0 0.2rem rgba(216, 216, 216, 0.5);
}

.btn-default.disabled,
.btn-default:disabled {
  color: #666666;
  background-color: #b8b8b8;
  border-color: #b8b8b8;
}

.btn-default:not(:disabled):not(.disabled):active,
.btn-default:not(:disabled):not(.disabled).active,
.show > .btn-default.dropdown-toggle {
  color: $textColor;
  background-color: #bdc1c5;
  border-color: #9d9ea2;
}

.btn-default:not(:disabled):not(.disabled):active:focus,
.btn-default:not(:disabled):not(.disabled).active:focus,
.show > .btn-default.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(188, 190, 194, 0.5);
}

.btn-outline-default {
  color: $textColor;
  border-color: #d4d6dc;
}

.btn-outline-default:hover {
  color: $textColor;
  background-color: #eef0f8;
  //border-color: #cccccc;
}

.btn-outline-default:focus,
.btn-outline-default.focus {
  box-shadow: 0 0 0 0.2rem rgba(188, 190, 194, 0.5);
}

.btn-outline-default.disabled,
.btn-outline-default:disabled {
  color: #6c757d;
  border-color: #b8b8b8;
  background-color: transparent;
}

.btn-outline-default:not(:disabled):not(.disabled):active,
.btn-outline-default:not(:disabled):not(.disabled).active,
.show > .btn-outline-default.dropdown-toggle {
  color: #666666;
  background-color: #8b8b8b;
  border-color: #7f7f7f;
}

.btn-outline-default:not(:disabled):not(.disabled):active:focus,
.btn-outline-default:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-default.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(195, 195, 195, 0.5);
}

.modal-content {
  .modal-header {
    background: none;
    padding: 0.6rem 1rem;
    border-bottom: 1px solid #fdfdff;

    h4.modal-title {
      font-family: $fontFamilySpot;
      font-size: 16px;
      font-weight: 600;
    }
  }

  .modal-body {
    padding: 1.5em;
  }

  .modal-footer {
    background: #fdfdff;
    border-top: none;
    padding: 0.6rem 1rem;
  }
}

.btn.disabled,
.btn:disabled,
.btn-primary.disabled,
.btn-primary:disabled {
  color: #a5a5a5;
  background-color: #ececec;
  border-color: #d6d6d6;
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.65;
}

.dropdown-item.disabled {
  background: #ececec;
  color: #a5a5a5;
  pointer-events: none;
  cursor: not-allowed;
}

.btn-course {
  font-family: $fontFamilyCommon;
  font-weight: 600;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  line-height: 2.4rem;
  padding: 0 10px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  cursor: pointer;
}

.btn-help {
  color: #fff;
  border-radius: 50%;
  background-color: #879099;
  border-color: #879099;

  fa-icon {
    padding-right: 4px !important;
    padding-left: 4px !important;
  }
}

.btn-help:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

button.close {
  color: $textColor;
}
