@import 'bootstrap-variables';
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'theme';
@import 'values';

/*
//use only to prevent font flickering.
.wf-loading body {
  visibility: hidden;
}

.wf-active body {
  visibility: visible;
}
*/

p-0 {
  padding: 0 !important;
}


body {
  position: relative;
}

.rich-editor {
  background: #fff;

  &.form-control {
    height: auto;
    display: inline-table;
    padding: 0;
    max-height: 500px;
    overflow: auto;

    quill-editor {
      max-height: 100%;

      .ql-toolbar.ql-snow {
        border-top: 0;
        border-left: 0;
        border-right: 0;
      }

      .ql-container.ql-snow {
        border: none;
      }
    }
  }
}

a:hover {
  cursor: pointer;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: $textColor;
  background: #f7faff;
}

a:not([href]):hover {
  color: $textColor;
}

.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

.error {
  color: white;
  background-color: red;
}

.pad {
  padding: 10px;
}

.w-5 {
  width: 5% !important;
}

.w-10 {
  width: 10% !important;
}

.w-20 {
  width: 20% !important;
}

.w-30 {
  width: 30% !important;
}

.w-40 {
  width: 40% !important;
}

.w-60 {
  width: 60% !important;
}

.w-70 {
  width: 70% !important;
}

.w-80 {
  width: 80% !important;
}

.w-90 {
  width: 90% !important;
}

.w-100 {
  width: 100% !important;
}

.break {
  white-space: normal;
  word-break: break-all;
}

.readonly {
  background-color: #eee;
  opacity: 1;
}

.hand,
[jhisortby] {
  cursor: pointer;
}

.sticky-header {
  position: sticky;
  top: 0;
  z-index: 1;
  background: #fff;
}

.fixed-table {
  table-layout: fixed;
}

/* ==========================================================================
entity detail page css
========================================================================== */
.row.jh-entity-details > {
  dd {
    margin-bottom: 15px;
  }
}

@media screen and (min-width: 768px) {
  .row.jh-entity-details > {
    dt {
      margin-bottom: 15px;
    }

    dd {
      border-bottom: 1px solid #eee;
      padding-left: 180px;
      margin-left: 0;
    }
  }
}

/* ==========================================================================
ui bootstrap tweaks
========================================================================== */
.nav,
.pagination,
.carousel,
.panel-title a {
  cursor: pointer;
}

.thread-dump-modal-lock {
  max-width: 450px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.btn-block {
  display: block;
  width: 100%;
}

.alerts {
  .alert-dismissible {
    padding-right: 1.25rem !important;
  }

  .alert {
    text-overflow: ellipsis;
    padding: 0.75rem 1.25rem;

    pre {
      background: none;
      border: none;
      font: inherit;
      color: inherit;
      padding: 0;
      margin: 0;
    }

    .popover pre {
      font-size: 10px;
    }

    .alert-error-details {
      margin-top: 10px;
      padding: 8px 12px;
      font-size: 90%;
      border: 1px solid #b58d91;
      border-radius: 2px;
      background: #f8f8f8;

      pre {
        font-size: 8px;
        font-family: monospace;
        max-height: 100px;
        overflow: auto;
        padding: 4px;
        border: 1px solid #8d8d8d;
        background: #ffffff;
        color: #000;
      }

      span {
        display: block;
      }
    }
  }

  .jhi-toast {
    top: 35px !important;
    position: fixed;
    width: 100%;
    z-index: 10000;

    &.center {
      //empty
    }

    &.left {
      left: 5px;
    }

    &.right {
      right: 5px;
    }

    &.top {
      top: 55px;
    }

    &.bottom {
      bottom: 55px;
    }
  }
}

@media screen and (min-width: 480px) {
  .alerts .jhi-toast {
    width: 50%;
    left: 25%;
  }
}

.card-header {
  background: #f7faff;
  border-bottom-color: $inputBorderColor;
  border: none;
  padding: 0.6rem 1.2rem;
  font-size: 14px;
  font-family: $fontFamilySpot;
  font-weight: 600;
}

.card-header.with-pagination {
  padding-top: 10px;
  padding-bottom: 9px;

  .pagination {
    .page-item {
      z-index: 0;
    }
  }
}

.entity-details {
  .card {
    border: none;
  }

  .card-header {
    background: none;
    padding: 0;
    border: none;

    .btn-link {
      letter-spacing: normal;
      color: $textColor;
      text-decoration: none !important;
      font-weight: normal;
      font-size: 18px;

      &:hover {
        color: $sidebarLinkHoverColor;
        text-decoration: none;
      }
    }
  }

  .card-body {
    padding: 1rem 1rem 1rem 2.6rem;

    pre.code {
      background: #f7f7f7;
      min-width: 600px;
      height: 400px;
      overflow: auto;
      white-space: pre-wrap;
      word-break: keep-all;
      padding: 4px;
      border: #dddddd;
    }
  }
}

.entity-child-section {
  .card {
    border: none;
  }

  .card-header {
    .btn-link {
      width: 100%;
      display: block;
      padding: 0;
      letter-spacing: normal;
      color: $textColor;
      text-decoration: none !important;

      &:hover {
        color: $sidebarLinkHoverColor;
        text-decoration: none;
      }
    }
  }
}

.list-empty-alert {
  .btn-link {
    letter-spacing: normal;
    text-decoration: none !important;

    &:hover {
      color: $sidebarLinkHoverColor;
      text-decoration: none;
    }

    &.disabled,
    &:disabled {
      border: transparent !important;
      background: transparent !important;
    }
  }
}

.dm-toast {
  .toast-header {
    //background: rgba(0, 0, 0, 0);
    background: rgba(150, 150, 150, 0.3);
  }

  .toast-body {
    //background: rgba(50, 50, 50, 0.3);
    background: rgba(200, 200, 200, 0.3);
  }
}

.form-control:focus {
  z-index: 2 !important;
}

.form-control.selector {
  .ng-dropdown-panel.ng-select-bottom {
    min-width: 350px;
  }

  .ng-select .ng-option {
    word-break: break-word;
    overflow: hidden;
    text-overflow: clip;
    display: block;
    line-break: auto;
    white-space: normal;

    .option-item {
      display: table;

      span {
        display: table-cell;
        vertical-align: middle;

        input[type='checkbox'] {
        }
      }

      label {
        padding-left: 0.5rem;
        display: table-cell;
        vertical-align: middle;
        background: none;
      }
    }
  }
}

span.required::after,
label.required::after {
  content: '*';
  padding-left: 0.2rem;
}

span.required::after {
  color: #f65e5e;
}

.input-group-text.required::after {
  content: '*';
  color: #f65e5e;
  font-weight: 600;
  padding-left: 0.2rem;
  font-size: 20px;
  line-height: 0;
}

.input-group-text.text-white.required::after {
  content: '*';
  color: #ffffff;
  font-weight: 600;
  padding-left: 0.2rem;
  font-size: 20px;
  line-height: 0;
}

.input-group-append {
  .btn {
    height: calc(1.5em + 0.75rem + 2px);
    line-height: 1rem;
  }
}

.input-group-prepend {
  .btn {
    height: calc(1.5em + 0.75rem + 2px);
    line-height: 1rem;
  }
}

textarea.form-control {
  margin-bottom: 0.2em;
}

.input-group > .form-control {
  //margin-bottom: 0.2em;
}

.input-group > ngx-codemirror {
  width: 100% !important;
  height: auto;
}

.input-group {
  &.informative {
    .input-group-prepend {
      .input-group-text {
        background: #ffffff;
        border: none;
        padding: 0 8px;
        font-weight: 600;
      }
    }

    .form-control {
      background: #ffffff;
      border: none;
    }
  }
}

.form-control.informative {
  background: #ffffff;
  border: none;
}

.nullLabel {
  display: block;
  height: 51px;
}

// input de imagem
.image-input-group {
  button {
    width: 100%;
    height: 100%;
    background: #fff2e0;
  }

  .img-holder {
    background-color: #fff2e0 !important;
    min-height: 139px;
    border-radius: 6px;

    .img-holder-btn {
      margin-left: 0;
      position: absolute;
      //top: 50%;
      //left: 47%;
      width: 97%;
      height: 74%;
    }
  }
}

.new-form-group {
  .form-control.selector .ng-select .ng-select-container {
    padding-top: 10px;
  }

  label {
    background: none;
    font-weight: normal;
    border: none;
    padding: 10px 0;
  }

  input {
    border: none;
    background: #f3f3f3;
    font-size: 14px;
    height: 45px;
    border-radius: 6px;
    margin-right: 2.5px;

    &:focus {
      background-color: #fafafa;
      outline: none;
    }
  }

  textarea {
    border: none;
    background: #f3f3f3;
    font-size: 14px;
    height: 139px;
    border-radius: 6px;
    margin-right: 2.5px;
    resize: none;

    &:focus {
      background-color: #fafafa;
      outline: none;
    }
  }

  select {
    border: none;
    background: #f3f3f3;
    -webkit-appearance: none;
    -moz-appearance: none;
    border-radius: 6px;
    height: 45px;
    font-size: 14px;
    margin-right: 2.5px;
  }

  .ng-select-container {
    border: none;
    background: #f3f3f3;
    -webkit-appearance: none;
    -moz-appearance: none;
    border-radius: 6px;
    height: 45px;
    font-size: 14px;
    margin-right: 2.5px;

    &:focus {
      background-color: #fafafa;
      outline: none;
    }
  }

  .form-control.selector {
    border: none;
  }
}

.btn-talk {
  color: white;
  padding: 10px 17px;
  border-radius: 2px;
  margin-top: 20px;
  margin-right: 5px;
  font-size: 14px;
  background: #0070d9;

  fa-icon {
    margin-right: 10px;
    color: white;
  }
}

.btn-waiting {
  color: white;
  padding: 10px 17px;
  border-radius: 2px;
  margin-top: 20px;
  margin-right: 5px;
  font-size: 14px;
  background: #3f8fef;

  fa-icon {
    margin-right: 10px;
    color: white;
  }
}

.btn-close-img {
  display: inline-block;
  position: relative;
}

.btn-close-img button {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
  border: 2px solid #fff;
  border-radius: 100px;
  background: rgba(255, 255, 255, 0.2);
  -webkit-transform: translate3d(-50%, -50%, 0);
  -moz-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}

.btn-close-img button:before {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 25px 0 25px 25px;
  border-color: transparent transparent transparent #ffffff;
  position: absolute;
  top: 25px;
  left: 42px;
}

.btn-close-img button:hover,
.btn-close-img button:after:hover {
  box-shadow: 0 0 0 4px rgba(150, 150, 100, 0.7);
}

[data-tooltip] {
  position: relative;
  font-weight: bold;
}

[data-tooltip]:after {
  display: none;
  position: absolute;
  top: -5px;
  padding: 5px;
  border-radius: 3px;
  left: calc(100% + 2px);
  content: attr(data-tooltip);
  white-space: nowrap;
  background-color: #0095ff;
  color: White;
}

.jhi-item-count {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0;
  margin: 0;
}

[data-tooltip]:hover:after {
  display: block;
}

.table-responsive .table.table-hover {
  background: #ffffff;
}

.justify-content-center {
  margin: 0 !important;

  .col-12 {
    padding: 0 !important;
  }
}

.window-content .row.justify-content-center .col-12 .card-body .row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.window-content .row.justify-content-center .col-12 > .card {
  margin-left: 1rem;
  margin-right: 1rem;
}

.image-card {
}

.dropzone {
  border: 3px dashed $inputBorderColor !important;
  min-height: 150px;
  background: white;
  padding: 20px;
  align-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: $textColor;

  &.form-control {
    border: 1px solid $inputBorderColor !important;
  }
}

.ql-toolbar.ql-snow,
.ql-container.ql-snow {
  border-color: $inputBorderColor;
}

.imageFile {
  &.form-control {
    display: block;
    min-height: 150px;

    img {
      max-height: 100%;
      width: auto;
      max-width: 100%;
    }
  }
}

.divNotExist {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10%;

  h1 {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
  }
}

.not-data {
  display: flex;
  justify-content: center;
  align-items: center;

  h1 {
    padding-top: 10%;
    text-align: center;
    font-weight: 600;
    font-size: 22px;
  }
}

button.btn,
a.btn {
  white-space: nowrap;
}

.vertical-input-group {
  .input-group {
    flex-direction: column;

    .input-group-prepend {
      padding-right: 1px;
    }

    textarea {
      border-radius: 0;
      resize: none;
      width: auto;
    }

    label {
      flex: 1;
      margin: 0;
      border-radius: 0;
      border-bottom: 0;
      height: 2.6rem;

      &.input-group-text {
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;

        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
    }

    .form-control {
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;

      border-bottom-left-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }
  }
}

.footer-pagination {
  position: absolute;
  bottom: $footerHeight;
  width: 100%;
  border-bottom: none;
}

.card-brand {
  max-height: 16px;
  width: auto;
  border-radius: 3px;
  margin-right: 0.6rem;
  align-self: center;
}

.modal-window-max {
  .modal-dialog {
    max-width: 98%;
    margin: auto;
  }

  .modal-content {
    margin: 20px auto auto;
  }

  .modal-dialog {
    margin: auto;
  }

  .modal-backdrop {
    background: none;
  }
}

ngb-pagination {
  z-index: 0;
}

.no-after-content::after {
  content: none !important;
}

iframe {
  &.box {
    height: 40vh;
    overflow: auto;
    min-width: 600px;
  }
}

quill-editor.is-invalid {

  .ql-toolbar,
  .ql-container {
    border-color: $appRed !important;
  }

}