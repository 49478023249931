// Persys patellet
$primary: #009fe3;
$onPrimary: #ffffff;
$primaryContainer: #ccecf9;
$onPrimaryContainer: #035786;

$surface: #f1f3f4;
$onSurface: #1b1b1f;
$surfaceVariant: #e1e2ed;
$onSurfaceVariant: #444650;

$secondary: #fae926;
$onSecondary: #212529;
$secondaryContainer: #ffdad7;
$onSecondaryContainer: #410004;

$outline: #757681;
$outlineVariant: #c5c6d1;
