@import 'patellet.scss';

$appColor: $primary;
$brandColor: $primary;
$textColor: $onSurface;
$fontFamilyCommon: 'Barlow', sans-serif;
$fontFamilySpot: 'Barlow', sans-serif;

$navbarHeight: 60px;
$footerHeight: 45px;
$sidebarWidth: 342px;

$backgroundColor: #fdfdff;

$sidebarLinkHoverColor: #4272d7;
$inputBorderColor: #dae1f5;
$inputDisabledBackgroundColor: #f0f1f9;
$itemHoverSelect: #f5faff;

$dirtyWarningColor: #d39e00;

$appOrange: #f59300;
$appRed: #cc0000;
$appBlue: #007cfb;
$appGreen: #05b52e;
$appGreenHover: #029323;
$appPurple: #7e00fb;


