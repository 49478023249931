/* after changing this file run 'yarn run webpack:build' */

/***************************
put Sass variables here:
eg $input-color: red;
****************************/

// Override Bootstrap variables
@import 'bootstrap-variables';

// Import Bootstrap source files from node_modules
@import 'node_modules/bootstrap/scss/bootstrap';
@import 'node_modules/@ng-select/ng-select/scss/default.theme';

// Import Quill Editor
@import 'node_modules/quill/dist/quill.core';
@import 'node_modules/quill/dist/quill.snow';

@import 'node_modules/flag-icons/css/flag-icons';

@import 'node_modules/grapesjs/src/styles/scss/main';

// codemirror
@import 'node_modules/codemirror/lib/codemirror';
@import 'node_modules/codemirror/theme/material';

// dropzone
@import 'node_modules/dropzone/dist/dropzone';
