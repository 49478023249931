body {
  padding: 0;
  margin: 0;
}

.app-loading {
  font-family: Helvetica, Arial, sans-serif;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.app-loading p {
  display: block;
  font-size: 1.17em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  font-weight: normal;
}

.app-loading .back {
  margin: 0 !important;
  padding: 0 !important;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  background: #ffffff;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
}

.app-loading .back .spinner {
  position: fixed;
  left: 50%;
  margin-left: -50px;
  top: 30%;
  margin-top: -50px;
  width: 120px;
  height: 120px;
  border: 1px solid transparent;
  border-radius: 10px;
  background: url('src/main/webapp/content/images/brand/icon.png') #ffffff center no-repeat;
  background-size: 60%;
  -webkit-animation: heart 2s ease-in-out 0s infinite normal;
  -moz-animation: heart 2s ease-in-out 0s infinite normal;
  -ms-animation: heart 2s ease-in-out 0s infinite normal;
  animation: heart 2s ease-in-out 0s infinite normal;
}

@keyframes heart {
  0% {
    transform: scale(0.95);
  }
  5% {
    transform: scale(1.1);
  }
  39% {
    transform: scale(0.85);
  }
  45% {
    transform: scale(1);
  }
  60% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(0.9);
  }
}
